var tarifs = {
  1: {
    1: 118,
    2: 118,
    3: 118,
    4: 96,
    5: 96,
    6: 75,
    7: 75,
    8: 75,
    9: 75,
    10: 96,
    11: 96,
    12: 118,
  },
  2: {
    1: 318,
    2: 299,
    3: 280,
    4: 241,
    5: 222,
    6: 203,
    7: 203,
    8: 222,
    9: 241,
    10: 280,
    11: 299,
    12: 318,
  },
  3: {
    1: 497,
    2: 463,
    3: 429,
    4: 394,
    5: 394,
    6: 394,
    7: 429,
    8: 463,
    9: 497,
    10: 532,
    11: 532,
    12: 532,
  },
  4: {
    1: 776,
    2: 776,
    3: 776,
    4: 776,
    5: 776,
    6: 776,
    7: 776,
    8: 776,
    9: 776,
    10: 776,
    11: 776,
    12: 776,
  },
};

document.addEventListener('turbolinks:load', function() {
  var select = document.querySelector('.v-tarifs-select');
  if (!select) return;

  var prices = document.querySelectorAll('.v-tarifs-price');
  var price1 = document.querySelector('.v-tarifs-price-1');
  var price2 = document.querySelector('.v-tarifs-price-2');
  var price3 = document.querySelector('.v-tarifs-price-3');
  var price4 = document.querySelector('.v-tarifs-price-4');

  updatePrice = (elem, abo, month) => {
    elem.innerHTML = tarifs[abo][month];
  }

  updatePrices = () => {
    let activeMonth = select.querySelector('option:checked').innerHTML.toLowerCase();
    updatePrice(price1, 1, select.value);
    updatePrice(price2, 2, select.value);
    updatePrice(price3, 3, select.value);
    updatePrice(price4, 4, select.value);
    prices.forEach((price) => {
      price.classList.add('do-animate');
      setTimeout(() => { price.classList.remove('do-animate') }, 500)
    });
    document.querySelectorAll('.v-tarifs-price__month').forEach(function(elem) {
      elem.innerHTML = activeMonth;
    });
  }

  updatePrices();
  select.addEventListener('change', elem => updatePrices());
});
